import { createContext, useEffect, useReducer, useState } from 'react';
import { MatxLoading } from 'app/components';
import $ from 'jquery';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: false, user };
    }

    default:
      return state;
  }
};

const setSession = (data) => {
  if (data) {
    sessionStorage.setItem('user', data);
  } else {
    sessionStorage.removeItem('user');
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [refresh, setRefresh] = useState(false);

  const login = async (email, password) => {
    const payload = { loginReseller: '', email, password };
    return new Promise((resolve, reject) => {
      $.ajax({
        dataType: 'json',
        type: 'POST',
        url: 'https://app.getcallsavvy.com/mw',
        data: { ...payload },
        success: (data) => {
          // console.log(data);
          if (data.status !== 'error') {
            const userData = data.data;
            const EncryptUserData = btoa(JSON.stringify(userData));
            resolve(EncryptUserData);
            setSession(EncryptUserData);
            setRefresh(true);
            dispatch({ type: 'LOGIN', payload: { user: userData } });
          } else {
            reject(data.message);
          }
        },
        error: (xhr, status, error) => {
          console.error('AJAX request failed:', status, error);
          reject('An error occurred. Please try again later.');
        }
      });
    });
  };

  const register = (name, brand_name, email, phone, password) => {
    const payload = {
      signupReseller: '',
      name,
      brand_name,
      email,
      phone,
      password
    };

    return new Promise((resolve, reject) => {
      $.ajax({
        dataType: 'json',
        type: 'POST',
        url: 'https://app.getcallsavvy.com/mw',
        data: { ...payload },
        success: (data) => {
          if (data.status !== 'error') {
            resolve(data.message);
          } else {
            reject(data.message);
          }
        },
        error: (xhr, status, error) => {
          console.error('AJAX request failed:', status, error);
          reject('An error occurred. Please try again later.');
        }
      });
    });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      const encodedUserData = sessionStorage.getItem('user');
      if (encodedUserData) {
        const decodedUserData = JSON.parse(atob(encodedUserData));
        //console.log(decodedUserData);
        const payload = { getResellerData: '', secureToken: decodedUserData.authToken };
        $.ajax({
          dataType: 'json',
          type: 'POST',
          url: 'https://app.getcallsavvy.com/mw',
          data: { ...payload },
          success: (data) => {
            if (data.status !== 'error') {
              const userData = data.data;
              userData.authToken = decodedUserData.authToken;
              console.log(userData);

              dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: userData } });
            } else {
              dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
            }
          },
          error: (xhr, status, error) => {
            console.error('AJAX request failed:', status, error);
            dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
          }
        });
      } else {
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, [refresh]);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
